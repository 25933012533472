<template>
  <BookingPaymentTemplate
    :headerProps="headerProps"
    :mediaInfo="mediaInfo"
    @submit="onSubmit"
  />
</template>

<script>
import BookingPaymentTemplate from "@/components/templates/BookingPayment/BookingPayment";
import { headerProps } from "@/components/constants";
import heroImg from "@/assets/images/sample-hero.jpg";

export default {
  components: {
    BookingPaymentTemplate,
  },
  data() {
    return {
      headerProps: { ...headerProps, currentRoute: this.$route.name },
      mediaInfo: [
        {
          image: heroImg,
        },
      ],
      content: {},
    };
  },
  created() {},
  methods: {
    onSubmit(data) {
      this.$router.push("/booked_result");
    },
  },
};
</script>

<style scoped>
</style>
