<template>
    <HeaderSection v-bind="headerProps" />
    <section class="hero-media">
		<Carousel :items="mediaInfo" />
	</section>

    <div class="content-wrap">
		<div class="entry entry-page">
            <PaymentForm :paymentForm="paymentForm" @submit="onSubmit" />
		</div>
        <CopyRight v-bind="footerProps"/>
	</div>
</template>

<script>
import HeaderSection from '../../organisms/Header/Header.vue';
import PaymentForm from '../../organisms/PaymentForm/PaymentForm.vue';
import Carousel from '../../organisms/Carousel/Carousel.vue';
import CopyRight from '../../molecules/CopyRight/CopyRight.vue';
import { footerProps } from '../../constants';
/**
 * - Use it to show a simple notification
 */
export default {
    components: {
        HeaderSection, PaymentForm, Carousel, CopyRight
    },
    props: {
        /**
         * Use it for notification title
         */
        headerProps: {
            type: Object,
            default: () => null
        },
        mediaInfo: {
            type: Object,
            default: () => null
        },
    },
    data() {
        return {
            footerProps,
            paymentForm: {
                cardNumber: null,
                expirationDate: null,
                zipCode: null,
                cvv: null,
            }
        }
    },
    methods: {
        onSubmit(data) {
            this.$emit('submit', data);
        }
    },
}
</script>

<style>

</style>